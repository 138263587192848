// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-bookshelf-js": () => import("./../../../src/pages/bookshelf.js" /* webpackChunkName: "component---src-pages-bookshelf-js" */),
  "component---src-pages-coding-problems-js": () => import("./../../../src/pages/coding_problems.js" /* webpackChunkName: "component---src-pages-coding-problems-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-js": () => import("./../../../src/pages/life.js" /* webpackChunkName: "component---src-pages-life-js" */)
}

